import { IRepairOrder, TFilters } from '@/types'
import { endpoints, usePaginatedRequest } from '@/apis'

export const useServiceAppointmentsByOrganization = ({
  enabled,
  refreshKey,
  ...rest
}: Partial<TFilters> & {
  enabled: boolean
  refreshKey?: string
}) => {
  const response = usePaginatedRequest<IRepairOrder>({
    enabled,
    params: {
      queryParams: Object.keys(rest)
        // @ts-ignore
        .filter(key => !!rest[key])
        // @ts-ignore
        .map(key => ({ [key]: `${rest[key]}` })),
    },
    refreshKey,
    path: endpoints.serviceAppointments,
  })
  return response
}
