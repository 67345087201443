import React, { useCallback, useMemo } from 'react'
import { capitalize } from 'lodash'
import moment from 'moment'

import { Button, DatePicker, Select, TextInput } from '@/components'
import {
  IFilterProps,
  IFilterResponse,
  IOrganization,
  IRepairOrdersFilter,
} from '@/types'
import { useDashboard, useOrganization } from '@/hooks'
import { Multiselect } from 'multiselect-react-dropdown'

export const Filters: React.FC<IFilterProps<IRepairOrdersFilter>> = ({
  filters,
  onReset,
  onUpdate,
}) => {
  const { locations } = useDashboard()
  const { organization, workflows } = useOrganization()

  const onSelectValue = useCallback(
    (field: keyof IRepairOrdersFilter) => (value?: any) => {
      let _value = value
      if (['startDate', 'endDate'].includes(field) && _value) {
        _value = moment(_value).toISOString()
      }
      onUpdate({ [field]: _value })
    },
    [onUpdate],
  )

  const locationList = useMemo(() => {
    if (!organization) return []
    else {
      return locations.filter(
        location => location._organization_id === organization._id,
      )
    }
  }, [organization, locations])

  const selectMapper = useCallback((data: IOrganization | IFilterResponse) => {
    return { value: data._id, label: capitalize(data.name) }
  }, [])

  const workflowList = useMemo(() => {
    return workflows.filter(
      workflow => workflow.type !== 'appointmentConfirmation',
    )
  }, [workflows])

  return (
    <div className="flex flex-col xl:flex-row gap-x-8 gap-y-4 py-5 items-end justify-between repair-order-filter">
      <div className="grid w-full gap-5 items-end md:grid-cols-5 grid-cols-1 sm:grid-cols-2">
        <TextInput
          label="RO Number"
          className="w-full"
          value={filters.externalId || ''}
          placeholder="Search by RO number..."
          onChange={e => onSelectValue('externalId')(e.target.value)}
        />
        <DatePicker
          label="Start Date"
          className="w-full"
          placeholder="Select start date"
          onChange={_value => {
            onSelectValue('startDate')(_value)
            const el = document.querySelectorAll(
              '.popover-trigger',
            )[1] as HTMLElement
            el?.click()
          }}
          maxDate={filters.endDate ? new Date(filters.endDate) : undefined}
          value={filters.startDate ? new Date(filters.startDate) : undefined}
        />
        <DatePicker
          label="End Date"
          className="w-full"
          placeholder="Select end date"
          onChange={onSelectValue('endDate')}
          value={filters.endDate ? new Date(filters.endDate) : undefined}
          minDate={filters.startDate ? new Date(filters.startDate) : undefined}
        />
        <Select
          label="Locations"
          className="w-full"
          value={filters.location}
          placeHolder="Select Location..."
          options={locationList.map(selectMapper)}
          onChange={option => onSelectValue('location')(option.value)}
        />
        <div className="sm:col-span-2 md:col-span-1 w-full">
          <span className="name">Workflows</span>
          <Multiselect
            placeholder={'Select Workflows'}
            className="multi-select"
            options={workflowList.map(option => ({
              id: option._id,
              name: option.name,
            }))}
            onSelect={selectedList =>
              onSelectValue('workflowIds')(selectedList)
            }
            onRemove={selectedList =>
              onSelectValue('workflowIds')(selectedList)
            }
            displayValue="name"
            selectedValues={
              filters.workflowIds?.map(id => {
                const workflow = workflowList.find(w => w._id === id)
                return { id: workflow?._id, name: workflow?.name }
              }) ?? []
            }
          />
        </div>
      </div>
      <div className="lg:min-w-max mt-5">
        <Button className="btn-primary !py-[9px]" onClick={onReset}>
          Reset filters
        </Button>
      </div>
    </div>
  )
}
