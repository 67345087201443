import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AxiosResponse } from 'axios'

import {
  TFunc,
  ITask,
  ILead,
  TFilters,
  IResponse,
  ISettings,
  IAppointment,
  ILeadsFilter,
  IAIBotCustomer,
  IAutomationData,
  IAppointmentFilter,
  IAIBotCustomerFilter,
  ICheckAccessResponse,
  ISingOrganizationType,
  IStats,
  IRepairOrder,
  IRepairOrdersFilter,
  IOrganizationWorkflowAllResponse,
  IWorkflowPartial,
  IServiceAppointment,
} from '@/types'
import {
  useAIBot,
  useLeads,
  useAllTasks,
  useCRMSettings,
  useAutomations,
  useCheckAccess,
  useAllAppointments,
  useSingleOrganization,
  IUsePaginatedRequestResponse,
  useStats,
  useOrganizationAllWorkflow,
} from '@/apis'
import { calculateStartEndDateWithTimezone, homePath } from '@/utils'

import {
  leadsDefaultFilters,
  dashboardDefaultFilters,
  appointmentsDefaultFilters,
  aiBotCustomersDefaultFilters,
} from '.'
import moment from 'moment-timezone'
import { useRepairOrdersByOrganization } from '@/apis/hooks/repair-orders'
import { useServiceAppointmentsByOrganization } from '@/apis/hooks/service-appointments'

interface IOrganizationContext {
  stats?: IStats
  refetch: TFunc
  hasAccessV2: boolean
  crmSettings: ISettings[]
  workflows: IWorkflowPartial[]
  isAutomationsLoading: boolean
  isOrganizationLoading: boolean
  automations: IAutomationData[]
  repairOrdersResponse: IUsePaginatedRequestResponse<IRepairOrder>
  serviceAppointmentsResponse: IUsePaginatedRequestResponse<IServiceAppointment>
  tasksFilter: Partial<TFilters>
  leadsFilter: Partial<ILeadsFilter>
  organization?: ISingOrganizationType
  onToggleAutomation: (_id: string) => void
  onDeletedAutomation: (_id: string) => void
  appointmentFilter: Partial<IAppointmentFilter>
  leadsResponse: IUsePaginatedRequestResponse<ILead>
  tasksResponse: IUsePaginatedRequestResponse<ITask>
  aiBotCustomersFilter: Partial<IAIBotCustomerFilter>
  repairOrdersFilter: Partial<IRepairOrdersFilter>
  serviceAppointmentsFilter: Partial<IRepairOrdersFilter>
  appointmentsResponse: IUsePaginatedRequestResponse<IAppointment>
  aiBotCustomersResponse: IUsePaginatedRequestResponse<IAIBotCustomer>
  onResetTasksFilter: TFunc
  onResetRepairOrdersFilter: TFunc
  onResetServiceAppointmentsFilter: TFunc
  onUpdateRepairOrdersFilter: (_filter: Partial<IRepairOrdersFilter>) => void
  onUpdateServiceAppointmentsFilter: (
    _filter: Partial<IRepairOrdersFilter>,
  ) => void
  onUpdateTasksFilter: (_filter: Partial<TFilters>) => void
  onResetAppointmentFilter: TFunc
  onUpdateAppointmentFilter: (_filter: Partial<IAppointmentFilter>) => void
  onResetLeadsFilter: TFunc
  onUpdateLeadsFilter: (_filter: Partial<ILeadsFilter>) => void
  onResetAiBotCustomersFilter: TFunc
  onUpdateAiBotCustomersFilter: (_filter: Partial<IAIBotCustomerFilter>) => void
}
export const OrganizationContext = createContext({} as IOrganizationContext)

const userTimezone = moment.tz.guess()

const repairOrdersDefaultFilter: Partial<TFilters> = {
  startDate: moment().subtract(7, 'd').toString(),
  endDate: moment().endOf('day').toString(),
}

export const OrganizationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [stats, setStats] = useState<IStats>()
  const [crmSettings, setCRMSettings] = useState<ISettings[]>([])
  const [workflows, setWorkflows] = useState<IWorkflowPartial[]>([])
  const [refreshTasks, setRefreshTasks] = useState<string>(`${Date.now()}`)
  const [refreshRepairOrders, setRefreshRepairOrders] = useState<string>(
    `${Date.now()}`,
  )
  const [refreshServiceAppointments, setRefreshServiceAppointments] =
    useState<string>(`${Date.now()}`)
  const [refreshAppointments, setRefreshAppointments] = useState<string>(
    `${Date.now()}`,
  )
  const [refreshLeads, setRefreshLeads] = useState<string>(`${Date.now()}`)
  const [refreshAiBotCustomers, setRefreshAiBotCustomers] = useState<string>(
    `${Date.now()}`,
  )
  const [hasAccessV2, setHasAccessV2] = useState<boolean>(false)
  const [tasksFilter, setTasksFilter] = useState<Partial<TFilters>>(
    dashboardDefaultFilters,
  )

  const [repairOrdersFilter, setRepairOrdersFilter] = useState<
    Partial<TFilters>
  >(repairOrdersDefaultFilter)
  const [serviceAppointmentsFilter, setServiceAppointmentsFilter] = useState<
    Partial<TFilters>
  >(repairOrdersDefaultFilter)
  const [automations, setAutomations] = useState<IAutomationData[]>([])
  const [leadsFilter, setLeadsFilter] =
    useState<Partial<ILeadsFilter>>(leadsDefaultFilters)
  const [aiBotCustomersFilter, setAiBotCustomersFilter] = useState<
    Partial<IAIBotCustomerFilter>
  >(aiBotCustomersDefaultFilters)
  const [appointmentFilter, setAppointmentFilter] = useState<
    Partial<IAppointmentFilter>
  >(appointmentsDefaultFilters)
  const [organization, setOrganization] = useState<ISingOrganizationType>()
  const { mutateAsync: fetchOrganization, isPending: isOrganizationLoading } =
    useSingleOrganization()

  const onAccessSuccess = useCallback((data: ICheckAccessResponse) => {
    setHasAccessV2(data.hasAccess)
  }, [])

  const onAutomationsSuccess = useCallback(
    (data: AxiosResponse<IAutomationData[]>) => {
      setAutomations(data.data)
    },
    [],
  )

  const onStatsSuccess = useCallback((res: AxiosResponse<IStats>) => {
    setStats(res.data)
  }, [])

  const { mutate: fetchStats } = useStats(onStatsSuccess)
  const aiBotOptions = useMemo(
    () => ({
      ...aiBotCustomersFilter,
      enabled: !!id,
      organization: id,
      refreshKey: refreshAiBotCustomers,
    }),
    [aiBotCustomersFilter, id, refreshAiBotCustomers],
  )
  const tasksOptions = useMemo(
    () => ({
      ...tasksFilter,
      ...calculateStartEndDateWithTimezone({
        start: tasksFilter.startDate,
        end: tasksFilter.endDate,
        timezone: userTimezone,
      }),
      enabled: !!id,
      organizationIds: id ? [id] : [],
      refreshKey: refreshTasks,
    }),
    [tasksFilter, id, refreshTasks],
  )

  const repairOrdersOptions = useMemo(() => {
    const filterValues = repairOrdersFilter
    if (!filterValues?.workflowIds?.length) {
      delete filterValues.workflowIds
    }
    return {
      ...filterValues,
      startDate: moment
        .tz(filterValues.startDate, userTimezone)
        .startOf('day')
        .utc()
        .toISOString(),
      endDate: moment
        .tz(filterValues.endDate, userTimezone)
        .endOf('day')
        .utc()
        .toISOString(),
      enabled: !!id,
      organization: id,
      refreshKey: refreshRepairOrders,
    }
  }, [repairOrdersFilter, id, refreshRepairOrders])

  const serviceAppointmentsOptions = useMemo(() => {
    const filterValues = serviceAppointmentsFilter
    if (!filterValues?.workflowIds?.length) {
      delete filterValues.workflowIds
    }
    return {
      ...filterValues,
      startDate: moment
        .tz(filterValues.startDate, userTimezone)
        .startOf('day')
        .utc()
        .toISOString(),
      endDate: moment
        .tz(filterValues.endDate, userTimezone)
        .endOf('day')
        .utc()
        .toISOString(),
      enabled: !!id,
      organization: id,
      refreshKey: refreshServiceAppointments,
    }
  }, [serviceAppointmentsFilter, id, refreshServiceAppointments])

  const leadsOptions = useMemo(
    () => ({
      ...leadsFilter,
      date: moment
        .tz(leadsFilter.date, userTimezone)
        .startOf('day')
        .utc()
        .toISOString(),
      enabled: !!id,
      organization: id,
      refreshKey: refreshLeads,
    }),
    [leadsFilter, id, refreshLeads],
  )
  const appointmentsOptions = useMemo(
    () => ({
      ...appointmentFilter,
      startDate: moment
        .tz(appointmentFilter.startDate, userTimezone)
        .startOf('day')
        .utc()
        .toISOString(),
      endDate: moment
        .tz(appointmentFilter.endDate, userTimezone)
        .endOf('day')
        .utc()
        .toISOString(),
      enabled: !!id,
      organization: id,
      refreshKey: refreshAppointments,
    }),
    [appointmentFilter, id, refreshAppointments],
  )

  const onCRMSettingsSuccess = useCallback((_data: IResponse<ISettings[]>) => {
    setCRMSettings(
      _data.data.sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      }),
    )
  }, [])

  const onWorkflowSuccess = useCallback(
    (_data: IOrganizationWorkflowAllResponse) => {
      setWorkflows(_data.workflows)
    },
    [],
  )

  const [checkAccess] = useCheckAccess(onAccessSuccess)
  const [getCRMSettings] = useCRMSettings(onCRMSettingsSuccess)
  const [getWorkflows] = useOrganizationAllWorkflow(onWorkflowSuccess)
  const { mutate: fetchAutomations, isPending: isAutomationsLoading } =
    useAutomations(onAutomationsSuccess)
  const leadsResponse = useLeads(leadsOptions)
  const tasksResponse = useAllTasks(tasksOptions)
  const repairOrdersResponse =
    useRepairOrdersByOrganization(repairOrdersOptions)
  const serviceAppointmentsResponse = useServiceAppointmentsByOrganization(
    serviceAppointmentsOptions,
  )
  const aiBotCustomersResponse = useAIBot(aiBotOptions)
  const appointmentsResponse = useAllAppointments(appointmentsOptions)

  const onOpenPage = useCallback(() => {
    if (!id) return
    fetchAutomations({
      id,
    })
  }, [fetchAutomations, id])

  useEffect(() => {
    onOpenPage()
  }, [onOpenPage])

  useEffect(() => {
    if (!id) {
      navigate(homePath)
      return
    }
    ;(async () => {
      try {
        checkAccess({ pathParams: { id } })
        getCRMSettings()
        getWorkflows({
          pathParams: { id },
        })
        const response = await fetchOrganization({
          id,
        })
        fetchStats({
          organization: id,
        })
        setOrganization(response.data)
      } catch (err) {
        navigate(homePath)
      }
    })()
  }, [
    id,
    fetchStats,
    fetchOrganization,
    checkAccess,
    navigate,
    getCRMSettings,
    getWorkflows,
  ])

  const onDeletedAutomation = useCallback((_id: string) => {
    setAutomations(prev => {
      const data = prev.filter(automation => automation._id !== _id)
      return [...data]
    })
  }, [])

  const onToggleAutomation = useCallback((_id: string) => {
    setAutomations(prev =>
      prev.map(automation =>
        automation._id === _id
          ? { ...automation, active: !automation.active }
          : automation,
      ),
    )
  }, [])

  // Trigger refresh when filters change
  useEffect(() => {
    setRefreshTasks(`${Date.now()}`)
  }, [tasksFilter, id])

  useEffect(() => {
    setRefreshAppointments(`${Date.now()}`)
  }, [appointmentFilter, id])

  useEffect(() => {
    setRefreshLeads(`${Date.now()}`)
  }, [leadsFilter, id])

  useEffect(() => {
    setRefreshAiBotCustomers(`${Date.now()}`)
  }, [aiBotCustomersFilter, id])

  useEffect(() => {
    setRefreshRepairOrders(`${Date.now()}`)
  }, [repairOrdersFilter, id])

  useEffect(() => {
    setRefreshServiceAppointments(`${Date.now()}`)
  }, [serviceAppointmentsFilter, id])

  // Filter functions
  const onResetTasksFilter = useCallback(() => {
    setTasksFilter(dashboardDefaultFilters)
  }, [])

  const onUpdateTasksFilter = useCallback((filter: Partial<TFilters>) => {
    setTasksFilter(prev => ({ ...prev, ...filter }))
  }, [])

  const onResetAppointmentFilter = useCallback(() => {
    setAppointmentFilter(appointmentsDefaultFilters)
  }, [])

  const onResetRepairOrdersFilter = useCallback(() => {
    setRepairOrdersFilter(repairOrdersDefaultFilter)
  }, [])

  const onResetServiceAppointmentsFilter = useCallback(() => {
    setServiceAppointmentsFilter(repairOrdersDefaultFilter)
  }, [])

  const onUpdateRepairOrdersFilter = useCallback(
    (filter: Partial<IRepairOrdersFilter>) => {
      setRepairOrdersFilter(prev => ({ ...prev, ...filter }))
      repairOrdersResponse.onPageMetaChange(1, 10)
    },
    [],
  )

  const onUpdateServiceAppointmentsFilter = useCallback(
    (filter: Partial<IRepairOrdersFilter>) => {
      setServiceAppointmentsFilter(prev => ({ ...prev, ...filter }))
      serviceAppointmentsResponse.onPageMetaChange(1, 10)
    },
    [],
  )

  const onUpdateAppointmentFilter = useCallback(
    (filter: Partial<IAppointmentFilter>) => {
      setAppointmentFilter(prev => ({ ...prev, ...filter }))
    },
    [],
  )

  const onResetLeadsFilter = useCallback(() => {
    setLeadsFilter(leadsDefaultFilters)
  }, [])

  const onUpdateLeadsFilter = useCallback((filter: Partial<ILeadsFilter>) => {
    setLeadsFilter(prev => ({ ...prev, ...filter }))
  }, [])

  const onResetAiBotCustomersFilter = useCallback(() => {
    setAiBotCustomersFilter(aiBotCustomersDefaultFilters)
  }, [])

  const onUpdateAiBotCustomersFilter = useCallback(
    (filter: Partial<IAIBotCustomerFilter>) => {
      setAiBotCustomersFilter(prev => ({ ...prev, ...filter }))
    },
    [],
  )
  //

  const value = useMemo(
    () => ({
      stats,
      tasksFilter,
      hasAccessV2,
      automations,
      repairOrdersResponse,
      leadsFilter,
      crmSettings,
      workflows,
      organization,
      tasksResponse,
      leadsResponse,
      appointmentFilter,
      onResetTasksFilter,
      onResetLeadsFilter,
      onToggleAutomation,
      onUpdateTasksFilter,
      repairOrdersFilter,
      onDeletedAutomation,
      refetch: onOpenPage,
      onUpdateLeadsFilter,
      aiBotCustomersFilter,
      isAutomationsLoading,
      appointmentsResponse,
      isOrganizationLoading,
      aiBotCustomersResponse,
      onResetAppointmentFilter,
      onUpdateRepairOrdersFilter,
      onResetRepairOrdersFilter,
      onUpdateAppointmentFilter,
      onResetAiBotCustomersFilter,
      onUpdateAiBotCustomersFilter,
      serviceAppointmentsResponse,
      serviceAppointmentsFilter,
      onUpdateServiceAppointmentsFilter,
      onResetServiceAppointmentsFilter,
    }),
    [
      stats,
      onOpenPage,
      tasksFilter,
      hasAccessV2,
      automations,
      workflows,
      repairOrdersResponse,
      leadsFilter,
      crmSettings,
      organization,
      tasksResponse,
      leadsResponse,
      appointmentFilter,
      onResetTasksFilter,
      onResetLeadsFilter,
      onToggleAutomation,
      onUpdateTasksFilter,
      onDeletedAutomation,
      onUpdateLeadsFilter,
      aiBotCustomersFilter,
      repairOrdersFilter,
      isAutomationsLoading,
      onResetRepairOrdersFilter,
      onUpdateRepairOrdersFilter,
      appointmentsResponse,
      isOrganizationLoading,
      aiBotCustomersResponse,
      onResetAppointmentFilter,
      onUpdateAppointmentFilter,
      onResetAiBotCustomersFilter,
      onUpdateAiBotCustomersFilter,
      serviceAppointmentsResponse,
      serviceAppointmentsFilter,
      onUpdateServiceAppointmentsFilter,
      onResetServiceAppointmentsFilter,
    ],
  )
  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  )
}
