import {
  ISample,
  IWorkflow,
  IUserResponse,
  ISamplesPayload,
  IWorkflowPayload,
  IToggleWorkflowPayload,
  IWorkflowTemplateResponse,
  ISingleOrganizationPayload,
  IOrganizationWorkflowResponse,
  IOrganizationWorkflowLocationsResponse,
  IOrganizationWorkflowAllResponse,
} from '@/types'
import { endpoints, useDelete, useLazyGet, usePost } from '@/apis'

export const useOrganizationWorkflow = (
  onSuccess?: (_data: IOrganizationWorkflowResponse) => void,
) => {
  return useLazyGet<ISingleOrganizationPayload, IOrganizationWorkflowResponse>(
    endpoints.organizationWorkflow,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useOrganizationAllWorkflow = (
  onSuccess?: (_data: IOrganizationWorkflowAllResponse) => void,
) => {
  return useLazyGet<
    ISingleOrganizationPayload,
    IOrganizationWorkflowAllResponse
  >(endpoints.organizationWorkflowAll, undefined, {
    onSuccess,
  })
}

export const useOrganizationWorkflowLocations = (
  onSuccess?: (_data: IOrganizationWorkflowLocationsResponse) => void,
) => {
  return useLazyGet<
    ISingleOrganizationPayload,
    IOrganizationWorkflowLocationsResponse
  >(endpoints.organizationWorkflowLocations, undefined, {
    onSuccess,
  })
}

export const useToggleWorkflow = () => {
  return usePost<IToggleWorkflowPayload, IWorkflow>(
    endpoints.toggleWorkflow,
    undefined,
    undefined,
  )
}

export const useDeleteWorkflow = () => {
  return useDelete<IToggleWorkflowPayload, string>(
    endpoints.deleteWorkflow,
    undefined,
    undefined,
  )
}

export const useGetSingleWorkflow = (
  onSuccess?: (_data: IWorkflow) => void,
) => {
  return useLazyGet<ISingleOrganizationPayload, IWorkflow>(
    endpoints.workflow,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useWorkflowTemplates = (
  onSuccess?: (_data: IWorkflowTemplateResponse) => void,
) => {
  return useLazyGet<ISingleOrganizationPayload, IWorkflowTemplateResponse>(
    endpoints.workflowTemplates,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useWorkflowUsers = (
  onSuccess?: (_data: IUserResponse) => void,
) => {
  return useLazyGet<ISingleOrganizationPayload, IUserResponse>(
    endpoints.workflowUsers,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useGetSampleResults = () => {
  return usePost<ISamplesPayload, ISample[]>(
    endpoints.getSampleResults,
    undefined,
    undefined,
  )
}

export const useCreateWorkflow = () => {
  return usePost<IWorkflowPayload, IWorkflow>(
    endpoints.createWorkflow,
    undefined,
    undefined,
  )
}

export const useEditWorkflow = () => {
  return usePost<IWorkflowPayload, IWorkflow>(
    endpoints.editWorkflow,
    undefined,
    undefined,
  )
}
