import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Multiselect } from 'multiselect-react-dropdown'
import { useToast } from '@/hooks'

import {
  TTag,
  TFunc,
  IPBSDealsType,
  IAutomationData,
  IPingTestResponse,
  IAutomationMutationPayload,
  IUserResponse,
  IOption,
} from '@/types'
import {
  Form,
  Select,
  Button,
  TextArea,
  TextInput,
  ToggleButton,
  FormError,
} from '@/components'
import {
  usePingStatus,
  useUploadImage,
  useEditAutomation,
  useAddNewAutomation,
  useGetLocationCredentials,
  useWorkflowUsers,
} from '@/apis'
import { useOrganization } from '@/hooks'

import {
  Activix,
  RequestState,
  WorkflowSelect,
  DealerSocketUUID,
  Uploader,
} from './components'

import { options, soldOptions, defaultState } from './'
import { checkForDynamicFieldsValidity } from '@/utils'
import Frequency from '@/pages/Organization/components/Automations/components/frequency'
import { frequencyDefaultState } from '@/pages/Organization/components/Automations/components/constants'

interface IAutomationFormProps {
  onClose?: TFunc
  automation?: IAutomationData
}

const reviewInviteUrlTag = '{{review_invite_url}}'

export const AutomationForm: React.FC<IAutomationFormProps> = ({
  onClose,
  automation,
}) => {
  const { addToast } = useToast()
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [pingResponse, setPingResponse] = useState<IPingTestResponse>()
  const timeout = useRef<ReturnType<typeof setTimeout>>()
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [currentState, setCurrentState] = useState(defaultState)
  const { organization, refetch } = useOrganization()
  const [users, setUsers] = useState<IOption[]>([])
  const [pbsDealStatusCache, setPbsDealStatusCache] = useState<string[]>([])

  const onPbsDealStatusSuccess = useCallback((data: IPBSDealsType) => {
    setPbsDealStatusCache(data.pbsDealStatusCache)
  }, [])

  const onGetUsersSuccess = useCallback((data: IUserResponse) => {
    setUsers(
      data.users.map(user => ({
        value: user._id,
        label: user.firstName + ' ' + user.lastName,
      })),
    )
    // @ts-ignore
    setCurrentState(prev => ({
      ...prev,
      assignedUsers:
        prev.assignedUsers
          ?.map(id => {
            const user = data.users.find(_v => _v._id === id)
            return user
              ? {
                  id: user._id,
                  name: user?.firstName + ' ' + user.lastName,
                }
              : null
          })
          .filter(el => el) || [],
    }))
  }, [])
  const [getUsers] = useWorkflowUsers(onGetUsersSuccess)

  const locations = useMemo(() => organization?.locations || [], [organization])
  const [getPbsDealStatusCache] = useGetLocationCredentials(
    onPbsDealStatusSuccess,
  )
  const { mutateAsync: fileUpload, isPending: isUploading } = useUploadImage()
  const {
    mutateAsync: editAutomation,
    isPending: isUpdateLoading,
    error: editError,
  } = useEditAutomation()
  const {
    mutateAsync: addAutomation,
    isPending: isCreatingLoading,
    error: addError,
  } = useAddNewAutomation()
  const { mutateAsync: pingStatus } = usePingStatus()

  useEffect(() => {
    if (editError || addError) {
      const err = editError || addError
      // @ts-ignore
      err?.response?.data?.details?.length &&
        // @ts-ignore
        addToast('error', err.response.data?.details?.[0].message)
    }
  }, [editError, addError, addToast])

  const onPingStatus = useCallback(
    (id: string) => {
      let done = false
      const interval = setInterval(async () => {
        if (done) {
          clearInterval(interval)
          return
        }
        const response = await pingStatus({
          id,
        })
        if (response.data.status !== 'pending') {
          done = true
          setPingResponse(response.data)
        }
      }, 5000)
    },
    [pingStatus],
  )

  const isLoading = useMemo(
    () => isUpdateLoading || isCreatingLoading,
    [isUpdateLoading, isCreatingLoading],
  )

  useEffect(() => {
    if (pingResponse && ['completed', 'failed'].includes(pingResponse.status)) {
      timeout.current = setTimeout(() => {
        clearTimeout(timeout.current)
        setPingResponse(undefined)
      }, 3000)
    }
  }, [pingResponse])

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current)
      setPingResponse(undefined)
    }
  }, [])

  const onChange = useCallback(
    (field: string) => (value: any) => {
      const isReset = field === 'locationId'
      const isReviewInvite = field === 'isReviewInvite'
      setCurrentState(prev => {
        const newState = {
          ...(isReset ? defaultState : prev),
          [field]: value,
        }

        if (isReviewInvite) {
          if (value) {
            // @ts-ignore
            newState.message = reviewInviteUrlTag
          } else {
            // @ts-ignore
            newState.message = ''
          }
        }
        return newState
      })
    },
    [],
  )

  const onChangeMeta = useCallback(
    (field: string) => (value: any) => {
      setCurrentState(prev => ({
        ...prev,
        meta: { ...prev.meta, [field]: value },
      }))
    },
    [],
  )

  const sortedRules = useMemo(
    () => organization?.rules?.sort((a, b) => a.name.localeCompare(b.name)),
    [organization?.rules],
  )

  const locationCredentials = useMemo(
    () =>
      // @ts-ignore
      organization?.credentials?.find(
        _v => _v.location === currentState?.locationId,
      ),
    [organization?.credentials, currentState?.locationId],
  )

  const onGetPbsDealStatusCache = useCallback(() => {
    if (!locationCredentials?.location) return
    getPbsDealStatusCache({
      pathParams: {
        id: locationCredentials.location,
      },
    })
  }, [getPbsDealStatusCache, locationCredentials?.location])

  const filteredRules = useMemo(
    () => sortedRules?.filter(_v => _v.crm === locationCredentials?.crm),
    [locationCredentials?.crm, sortedRules],
  )

  const rule = useMemo(() => {
    const currentRule = organization?.rules?.find(
      _v => _v._id === currentState?.ruleId,
    )
    if (currentRule && currentRule?.dynamicFields) {
      currentRule.dynamicFields.push('organization_name', 'location_name')
      currentRule.dynamicFields = [...new Set(currentRule.dynamicFields)]
    }

    return currentRule
  }, [organization?.rules, currentState?.ruleId])

  useEffect(() => {
    if (rule?.type === 'automation' && organization?._id) {
      getUsers({ pathParams: { organization: organization._id } })
    }
  }, [getUsers, organization?._id, rule?.type])

  const onAddMessage = useCallback(
    (field: string) => () => {
      const message = currentState?.message || ''
      const cursorPosition = textareaRef?.current?.selectionStart
      const newMessage = `${message.slice(
        0,
        cursorPosition,
      )}{{${field}}}${message.slice(cursorPosition)}`
      // @ts-ignore
      setCurrentState(prev => ({
        ...prev,
        message: newMessage,
      }))
    },
    [currentState?.message],
  )

  const inputLabel = useMemo(
    () =>
      rule?.type === 'automation'
        ? 'Broadcast Name'
        : rule?.type === 'workflow'
          ? 'Workflow Name'
          : undefined,
    [rule?.type],
  )

  useEffect(() => {
    if (automation) {
      // @ts-ignore
      setCurrentState(prev => ({
        ...prev,
        edit: true,
        meta: automation?.meta,
        name: automation?.name,
        ruleId: automation?.rule,
        message: automation?.message,
        automationId: automation._id,
        media: automation.media,
        locationId: automation?.location._id,
        end: automation?.endOffset?.value,
        endUnit: automation?.endOffset?.unit,
        start: automation?.startOffset?.value,

        startUnit: automation?.startOffset?.unit,
        frequencies: automation?.frequencies?.length
          ? automation.frequencies.map(freq => ({
              increment: freq.increment,
              time: freq.time,
              days: [1],
              weekdays: freq.weekdays || [],
            }))
          : [
              {
                ...frequencyDefaultState,
                time: {
                  ...frequencyDefaultState.time,
                  isoTimezone: organization?.timezone,
                },
              },
            ],

        isReviewInvite: automation?.message === reviewInviteUrlTag,
        assignedUsers: automation?.assignedUsers || [],
      }))
    }
  }, [automation, organization?.timezone])

  useEffect(() => {
    if (locationCredentials?.crm === 'pbs') {
      onGetPbsDealStatusCache()
    }
  }, [onGetPbsDealStatusCache, locationCredentials?.crm])

  useEffect(() => {
    setErrors(prev => ({ ...prev, message: '' }))
  }, [currentState.message])

  const uploadImage = useCallback(async () => {
    try {
      const response = await fileUpload({ image: currentState.image })
      if (!!response.data.data) {
        // @ts-ignore
        setCurrentState(prev => ({
          ...prev,
          image: null,
          imageError: null,
          media: response.data.data,
        }))
      }
    } catch (err: any) {
      setCurrentState(prev => ({
        ...prev,
        imageError: err?.response?.data || null,
      }))
    }
  }, [currentState?.image, fileUpload])

  const onRemove = useCallback(() => {
    setCurrentState(prev => ({
      ...prev,
      image: null,
      media: null,
      imageError: null,
    }))
  }, [])

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setErrors({})

      if (!currentState?.isReviewInvite) {
        const canSubmit = checkForDynamicFieldsValidity(
          currentState?.message || '',
          rule?.dynamicFields,
        )

        if (!canSubmit) {
          setErrors(prev => ({
            ...prev,
            message: 'Dynamic fields can be any of available fields only.',
          }))
          return
        }
      }

      const body: IAutomationMutationPayload = {
        id: currentState?.edit ? currentState.automationId : undefined,
        location: currentState?.locationId,
        name: currentState?.name,
        crm: locationCredentials?.crm,
        rule: currentState?.ruleId,
        message: currentState?.message,
        startOffset: [currentState?.startUnit, currentState?.start],
        endOffset: [currentState?.endUnit, currentState?.end],
        meta: currentState?.meta,
        frequencies: currentState.frequencies,
        media: currentState.media,
        assignedUsers: undefined,
      }

      if (rule?.type === 'automation' || rule?.type === 'resurrection') {
        if (!currentState?.frequencies?.length) {
          setErrors({
            frequencies: 'There must be at least one frequency',
          })
          return
        }
      }
      if (rule?.type === 'appointment') {
        body.name = 'Appointments Confirmations'
        body.message = 'none'
        body.frequencies = undefined
      }

      if (rule?.type === 'lead') {
        // @ts-ignore
        if (currentState?.meta?.source_description) {
          body.name =
            // @ts-ignore
            'Lead Importer (' + currentState?.meta.source_description + ')'
          // @ts-ignore
        } else if (currentState?.meta?.source_name) {
          // @ts-ignore
          body.name = 'Lead Importer (' + currentState?.meta.source_name + ')'
          // @ts-ignore
        } else if (currentState?.meta?.lead_source) {
          // @ts-ignore
          body.name = 'Lead Importer (' + currentState?.meta.lead_source + ')'
          // @ts-ignore
        } else if (currentState?.meta?.leadsource) {
          // @ts-ignore
          body.name = 'Lead Importer (' + currentState?.meta.leadsource + ')'
          // @ts-ignore
        } else if (currentState?.meta?.source) {
          // @ts-ignore
          body.name = 'Lead Importer (' + currentState?.meta.source + ')'
          // @ts-ignore
          if (currentState?.meta?.subSource) {
            // @ts-ignore
            body.name += ' (' + currentState?.meta.subSource + ')'
          }
        } else {
          body.name = 'Lead Importer'
        }
        body.message = 'none'
        body.frequencies = undefined
      }

      if (rule?.type === 'workflow') {
        body.message = 'none'
        body.frequencies = undefined
      }

      if (rule?.type === 'aibot') {
        body.name = 'AI Bot Manager'
        // @ts-ignore
        if (currentState?.meta?.source) {
          // @ts-ignore
          body.name += ' (' + currentState?.meta.source + ')'
          // @ts-ignore
          if (currentState?.meta?.subSource) {
            // @ts-ignore
            body.name += ' (' + currentState?.meta.subSource + ')'
          }
        }
        body.message = 'none'
        body.frequencies = undefined
      }

      if (rule?.type === 'weeklyreport') {
        body.name = 'Weekly automations report'
        body.message = 'none'
        body.frequencies = undefined
      }

      if (rule?.type === 'resurrection') {
        // @ts-ignore
        body.name = 'Resurrection ' + currentState?.meta?.source_name
      }
      if (rule?.type === 'automation') {
        // @ts-ignore
        body.assignedUsers = currentState?.assignedUsers.map(user => user.id)
      }

      const request = currentState?.edit ? editAutomation : addAutomation

      try {
        const response = await request(body)
        onClose?.()
        refetch()
        setCurrentState(defaultState)
        setPbsDealStatusCache([])
        if (response.data !== 'OK') {
          setPingResponse({ status: 'pending' })
          onPingStatus(response.data)
        }
      } catch (err) {
        console.log(err, 'error')
      }
    },
    [
      currentState?.isReviewInvite,
      currentState?.edit,
      currentState.automationId,
      currentState?.locationId,
      currentState?.name,
      currentState?.ruleId,
      currentState?.message,
      currentState?.startUnit,
      currentState?.start,
      currentState?.endUnit,
      currentState?.end,
      currentState?.meta,
      currentState?.frequencies,
      currentState?.media,
      currentState?.assignedUsers,
      locationCredentials?.crm,
      organization?.timezone,
      rule?.type,
      rule?.dynamicFields,
      editAutomation,
      addAutomation,
      onClose,
      refetch,
      onPingStatus,
    ],
  )

  return (
    <Form className="automation-form px-2" onSubmit={onSubmit}>
      {!!pingResponse && <RequestState {...pingResponse} />}
      <div className="lg:w-1/2">
        <Select
          placeHolder="Select"
          className="w-full block"
          label="Select a location"
          value={currentState?.locationId}
          onChange={option => onChange('locationId')(option?.value)}
          options={
            locations?.map(_v => ({
              value: _v._id,
              label: _v.name,
            })) || []
          }
        />

        {!!locationCredentials && (
          <Select
            placeHolder="Select"
            value={currentState?.ruleId}
            className="mt-2 block w-full"
            label={`Select a report type (CRM: ${locationCredentials.crm})`}
            onChange={option => onChange('ruleId')(option?.value)}
            options={(filteredRules || [])?.map(_v => ({
              value: _v._id,
              label: _v.viewName,
            }))}
          />
        )}

        {rule?.type === 'automation' && (
          <React.Fragment>
            <h6 className="text-sm font-medium my-2">
              Assign users to the automated campaign's conversations.
            </h6>
            <Multiselect
              className="multi-select mb-2"
              options={users.map(user => ({
                id: user.value,
                name: user.label,
              }))}
              onSelect={selectedList => onChange('assignedUsers')(selectedList)}
              onRemove={selectedList => onChange('assignedUsers')(selectedList)}
              displayValue="name"
              selectedValues={currentState?.assignedUsers || []}
            />
          </React.Fragment>
        )}

        {rule?.customFields?.map((field, idx) => (
          <React.Fragment key={idx}>
            {field?.tag === TTag.TEXTAREA && (
              <TextArea
                rows={3}
                required
                label={field?.viewName}
                aria-label="With textarea"
                onChange={e => onChangeMeta(field.name)(e.target.value)}
                // @ts-ignore
                value={currentState?.meta?.[field.name]}
                subLabel="Dynamic fields are supported"
              />
            )}
            {field?.tag === TTag.INPUT && (
              <TextInput
                autoComplete="off"
                type={field.type}
                label={field?.viewName}
                className="block w-full mt-2"
                onChange={e => onChangeMeta(field.name)(e.target.value)}
                // @ts-ignore
                value={currentState?.meta?.[field.name]}
              />
            )}
            {field?.tag === TTag.DEALERSOCKET_UUID && (
              <DealerSocketUUID
                label={field?.viewName}
                onChange={onChangeMeta(field.name)}
                // @ts-ignore
                value={currentState?.meta?.[field.name]}
              />
            )}
            {field?.tag === TTag.WORKFLOW_SELECT && (
              <WorkflowSelect
                label={field?.viewName}
                onChange={onChangeMeta(field.name)}
                locationId={currentState?.locationId}
                // @ts-ignore
                value={currentState?.meta?.[field.name]}
              />
            )}
            {field?.tag === TTag.INPUT_NOT_REQUIRED && (
              <TextInput
                autoComplete="off"
                type={field.type}
                className="mt-2 block"
                label={field?.viewName}
                onChange={e => onChangeMeta(field.name)(e.target.value)}
                // @ts-ignore
                value={currentState?.meta?.[field.name]}
              />
            )}

            {field?.tag === TTag.SELECT && (
              <Select
                label={field?.viewName}
                options={(field?.options || []).map(option => ({
                  value: option,
                  label: `${option}`,
                }))}
                name={field.name}
                className="mt-2 block w-full"
                // @ts-ignore
                value={currentState?.meta?.[field.name]}
                onChange={_v => onChangeMeta(field.name)(_v.value)}
              />
            )}
            {field?.tag === TTag.SELECT_V2 && (
              <Select
                label={field?.viewName}
                options={(field?.options || []).map(option => ({
                  label: option?.name,
                  value: option?.value,
                }))}
                name={field.name}
                className="w-full block"
                // @ts-ignore
                value={currentState?.meta?.[field.name]}
                onChange={_v => onChangeMeta(field.name)(_v.value)}
              />
            )}
            {field?.tag === TTag.START_END_SOLD_ELEAD && (
              <React.Fragment>
                <h6 className="text-sm font-medium my-2">{field?.viewName}</h6>
                <div className="flex gap-5 w-full">
                  <div className="flex items-end gap-2">
                    <Select
                      required
                      name="start_2"
                      label="Start offset"
                      options={soldOptions}
                      className="w-1/2 block"
                      // @ts-ignore
                      value={currentState?.meta?.[field.name]}
                      onChange={_v => onChangeMeta(field.name)(_v.value)}
                    />
                    <Select
                      required
                      name="startUnit_2"
                      options={options}
                      className="w-1/2 block"
                      // @ts-ignore
                      value={currentState?.meta?.[field.name]}
                      onChange={_v => onChangeMeta(field.name)(_v.value)}
                    />
                  </div>
                  <div className="flex items-end gap-2">
                    <Select
                      required
                      name="end_2"
                      label="End offset"
                      options={soldOptions}
                      className="w-1/2 block"
                      // @ts-ignore
                      value={currentState?.meta?.[field.name]}
                      onChange={_v => onChangeMeta(field.name)(_v.value)}
                    />
                    <Select
                      required
                      name="endUnit_2"
                      options={options}
                      className="w-1/2 block"
                      // @ts-ignore
                      value={currentState?.meta?.[field.name]}
                      onChange={_v => onChangeMeta(field.name)(_v.value)}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
            {field?.tag === TTag.MULTIPLE_SELECT && (
              <React.Fragment>
                <h6 className="text-sm font-medium my-2">{field?.viewName}</h6>
                <Multiselect
                  className="multi-select"
                  options={(field?.options || []).map(option => ({
                    id: option,
                    name: option,
                  }))}
                  onSelect={selectedList =>
                    onChangeMeta(field.name)(
                      encodeURI(
                        // @ts-ignore
                        JSON.stringify(selectedList.map(option => option.name)),
                      ),
                    )
                  }
                  onRemove={selectedList =>
                    onChangeMeta(field.name)(
                      encodeURI(
                        // @ts-ignore
                        JSON.stringify(selectedList.map(option => option.name)),
                      ),
                    )
                  }
                  displayValue="name"
                  selectedValues={
                    // @ts-ignore
                    currentState?.meta?.[field.name]
                      ? JSON.parse(
                          // @ts-ignore
                          decodeURI(currentState?.meta[field.name]),
                          // @ts-ignore
                        ).map(option => ({
                          id: option,
                          name: option,
                        }))
                      : []
                  }
                />
              </React.Fragment>
            )}
            {field?.tag === TTag.PBS_MULTISELECT && (
              <React.Fragment>
                <h6 className="text-sm font-medium my-2">{field?.viewName}</h6>
                <Multiselect
                  className="multi-select"
                  options={pbsDealStatusCache?.map(name => ({
                    name,
                    id: name,
                  }))}
                  onSelect={selectedList =>
                    onChangeMeta(field.name)(
                      selectedList.map((_v: any) => _v.id),
                    )
                  }
                  onRemove={selectedList =>
                    onChangeMeta(field.name)(
                      selectedList.map((_v: any) => _v.id),
                    )
                  }
                  displayValue="name"
                  // @ts-ignore
                  selectedValues={currentState?.meta?.[field.name]?.map(
                    (name: string) => ({
                      name,
                      id: name,
                    }),
                  )}
                />
              </React.Fragment>
            )}
            {field?.tag === TTag.ACTIVIX_FILTER && (
              <Activix
                field={field}
                onChange={onChangeMeta(field.name)}
                current={currentState?.meta?.[field.name]}
              />
            )}
          </React.Fragment>
        ))}

        {inputLabel && (
          <TextInput
            autoComplete="off"
            required
            label={inputLabel}
            value={currentState?.name}
            className="mt-2 w-full block"
            onChange={e => onChange('name')(e.target.value)}
          />
        )}

        {(rule?.type === 'automation' || rule?.type === 'resurrection') &&
          !currentState.isReviewInvite && (
            <React.Fragment>
              <TextArea
                required
                rows={3}
                label="Message"
                canResize={false}
                aria-label="With textarea"
                className="w-full mt-2 block"
                value={currentState?.message}
                subLabel="Dynamic fields are supported"
                onChange={e => onChange('message')(e.target.value)}
                ref={textareaRef}
                error={errors?.message}
              />
              <p className="block text-xs font-normal leading-5 mt-2 text-gray-500">
                Available dynamic fields
              </p>
              <div className="flex flex-wrap gap-2 mt-1">
                {rule?.dynamicFields?.map((field, idx) => {
                  return (
                    <Button
                      key={idx}
                      type="button"
                      className="dynamic-fields"
                      onClick={onAddMessage(field)}
                    >
                      {field}
                    </Button>
                  )
                })}
              </div>
            </React.Fragment>
          )}
        {(rule?.type === 'automation' || rule?.type === 'resurrection') && (
          <ToggleButton
            title="Use the location's review invite script ?"
            className="mt-3"
            checked={currentState.isReviewInvite}
            onChange={_v => onChange('isReviewInvite')(_v)}
          ></ToggleButton>
        )}
      </div>

      <div className="lg:w-1/2 py-4 flex flex-col justify-between">
        <div>
          {errors.frequencies && <FormError error={errors.frequencies} />}
          <Frequency
            currentState={currentState}
            onChange={onChange}
            rule={rule}
            organization={organization}
            setCurrentState={setCurrentState}
          />
        </div>

        {rule?.type === 'automation' &&
          rule &&
          rule?.crm !== 'vinsolutions' &&
          rule?.crm !== 'subarucareconnect' &&
          rule?.crm !== 'dealercenter' && (
            <Uploader
              onRemove={onRemove}
              uploadImage={uploadImage}
              imageUrl={currentState.media?.url}
              error={!!currentState.imageError}
              setFile={file => onChange('image')(file)}
              file={currentState?.image}
              isUploading={isUploading}
            />
          )}
        <div className="flex justify-end">
          <Button
            type="submit"
            disabled={isLoading || isUploading}
            className="btn btn-primary btn-save"
          >
            Save automation
          </Button>
        </div>
      </div>
    </Form>
  )
}
