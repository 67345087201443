import React, { createContext, useEffect, useMemo, useState } from 'react'
import { InventoryType, IUnusedFeed, IUnusedFeedsResponse } from '@/types'
import { useUnusedFeedsRequest } from '@/apis/hooks/inventory'

interface IUnusedFeedsContext {
  feeds: Record<InventoryType, IUnusedFeed[]>
}
export const UnusedFeedsContext = createContext({} as IUnusedFeedsContext)

export const UnusedFeedsContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [feeds, setFeeds] = useState<Record<InventoryType, IUnusedFeed[]>>({
    homenet: [],
    vauto: [],
    sm360: [],
    dealersync: [],
    carsforsales: [],
    dealerspike: [],
    edealer: [],
    vincue: [],
    serti: [],
  })
  const onUnusedDealersSuccess = (data: IUnusedFeedsResponse) => {
    setFeeds(data.data)
  }
  const [getUnusedDealers] = useUnusedFeedsRequest(onUnusedDealersSuccess)

  useEffect(() => {
    getUnusedDealers()
  }, [getUnusedDealers])

  const value = useMemo(
    () => ({
      feeds,
    }),
    [feeds],
  )

  return (
    <UnusedFeedsContext.Provider value={value}>
      {children}
    </UnusedFeedsContext.Provider>
  )
}
